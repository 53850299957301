import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VNavigationDrawer,_vm._g(_vm._b({attrs:{"bottom":"","color":"transparent","fixed":"","height":"auto","overlay-color":"secondary","overlay-opacity":".8","temporary":""}},'v-navigation-drawer',_vm.$attrs,false),_vm.$listeners),[_c(VList,{attrs:{"color":"white","shaped":""}},_vm._l((_vm.items),function(item,i){return _c(VListItem,{key:i,attrs:{"to":item.to,"exact":item.name === 'home',"color":"primary"}},[_c(VListItemContent,[_c(VListItemTitle,{attrs:{"v-text":item.text}},[_vm._v(" "+_vm._s(item.text)+" ")])],1)],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }